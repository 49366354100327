import React from 'react'
import { Link } from 'gatsby'
import profileImage from '../img/profile.png'
import './scoped-style/SideSection.scss'

const SideSection = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <section className="section section--profile">
        <div className="content">
          <h3 className="has-text-weight-semibold is-size-4">プロフィール</h3>
          <img className="profile-image" src={profileImage} alt="profile"></img>

          <div className="profile-descript">ヨガと旅行を愛し、仕事と子育てに奮闘する『 由琉(ゆる)』です。</div>
          <div className="profile-descript">仕事、留学、旅行などで数十各国を渡航し、長期海外滞在やバックパッカーの経験有り。</div>

          <div className="profile-descript">働きながら国家公務員に転職し、某省庁に勤務中。</div>
          <div className="profile-descript">フラダンス、マリンスポーツなども趣味とし、定年後夫婦でのんびり南国の海辺に住むことに憧れます。</div>

          <div className="profile-descript">自由奔放に今を楽しみ、日々｢楽しいことないかな〜!｣とポジティブに過ごしてます♪</div>
        </div>
      </section>
    )
  }
}

export default SideSection